import styled from 'styled-components'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { Wrapper } from '@pretto/bricks/components/layout/Wrapper/styles'
import Image from '@pretto/bricks/website/shared/components/Image'

export const Page = styled.div``
export const Header = styled(Wrapper)`
  margin-top: ${g(5)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    background: url('https://res.cloudinary.com/pretto-fr/image/upload/q_auto,w_512/v1572280069/website/page/podcast/podcast-cover.png')
      no-repeat right top;
    background-size: 256px auto;
  }
  @media screen and (min-width: ${breakpoints.laptop}) {
    background-position: 80% top;
  }
`
export const Information = styled.p`
  margin-top: ${g(3)};
  padding-bottom: ${g(3)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: 50%;
    padding-bottom: ${g(18)};
  }
`

export const SoundCloudContainer = styled.div`
  position: relative;

  &:after {
    position: absolute;
    top: 166px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.legacy.colors.primary1.default};
    content: '';
    z-index: -1;
  }
`

export const SoundCloudWrapper = styled(Wrapper)`
  margin-bottom: ${g(4)};
`

export const SoundCloud = styled.div`
  padding-bottom: ${g(2)};
`

export const Links = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${g(20)}, 1fr));
  grid-gap: ${g(3)};
  padding-bottom: ${g(2)};
`
export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${g(3)};
  border-radius: ${g(2)};
  height: ${g(12)};
  background-color: ${({ luminosity, theme }) =>
    luminosity === 'dark' ? theme.legacy.colors.neutral1.dark : theme.legacy.colors.white.default};
`

export const LinkImage = styled(Image).attrs({ options: { crop: 'scale', height: ng(6) } })`
  display: block;
`
