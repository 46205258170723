import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

export const Content = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }
`

export const Page = styled.div`
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }
`
