import { Grid } from '@pretto/bricks/components/layout/Grid'

import Ebook from '@pretto/website/src/components/Ebook'
import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'

import PropTypes from 'prop-types'
import { useContext } from 'react'

import * as S from './Podcast.styles'

export const Podcast = ({ headerComponent }) => {
  const { contentComponent } = useContext(PostContentContext)

  return (
    <>
      <S.Page>
        {headerComponent}

        <Grid>
          <S.Content>{contentComponent}</S.Content>
        </Grid>
      </S.Page>

      <Ebook />
    </>
  )
}

Podcast.propTypes = {
  headerComponent: PropTypes.node.isRequired,
}
