import SitePodcastPage from '@pretto/bricks/website/podcast/pages/SitePodcastPage'

import Schema from '@pretto/website/src/components/Schema'
import { Podcast as PodcastView } from '@pretto/website/src/templates/podcast/views/Podcast/Podcast'
import { trackAction } from '@pretto/website/src/utilities'

import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef } from 'react'

import { LINKS, SOUNDCLOUD } from './config'

const trackPlatform = platform => {
  trackAction('Podcast Platform Tracked', { podcast_platform: platform })
}

export const Podcast = ({ data, ...props }) => {
  const iframeRef = useRef(null)

  useEffect(() => {
    const { contentWindow } = iframeRef.current

    if (!contentWindow) {
      return
    }

    const handleMessage = message => {
      if (!message.origin.includes('soundcloud.com')) {
        return
      }

      const { method } = JSON.parse(message.data)

      switch (method) {
        case 'ready':
          contentWindow.postMessage(
            JSON.stringify({
              method: 'addEventListener',
              value: 'play',
            }),
            '*'
          )
          break

        case 'play':
          trackPlatform('soundcloud')
          window.removeEventListener('message', handleMessage)
          break

        default:
          break
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleClick = trackPlatform

  const pageProps = {
    information: data.excerpt,
    links: LINKS.map(({ id, ...link }) => ({
      ...link,
      onClick: handleClick.bind(null, id),
    })),
    soundcloud: SOUNDCLOUD,
    title: data.title,
  }

  const headerComponent = useMemo(() => <SitePodcastPage {...pageProps} ref={iframeRef} />, [])

  return (
    <>
      <PodcastView {...props} headerComponent={headerComponent} />

      <Schema type="podcast" />
    </>
  )
}

Podcast.propTypes = {
  data: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
