import Heading from '@pretto/bricks/components/typography/Heading'

import PropTypes from 'prop-types'
import { forwardRef, memo } from 'react'

import * as S from './styles'

const SitePodcastPage = forwardRef(({ information, links, soundcloud, title }, ref) => (
  <S.Page>
    <S.Header>
      <Heading type="h1">{title}</Heading>
      <S.Information>{information}</S.Information>
    </S.Header>
    <S.SoundCloudContainer>
      <S.SoundCloudWrapper>
        <S.SoundCloud>
          <iframe
            ref={ref}
            title={soundcloud.title}
            width="100%"
            height="345"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src={soundcloud.src}
          />
        </S.SoundCloud>

        <S.Links>
          {links.map(({ logo, onClick, path, theme }, i) => (
            <li key={i}>
              <S.Link href={path} onClick={onClick} target="blank" luminosity={theme}>
                <S.LinkImage path={logo} />
              </S.Link>
            </li>
          ))}
        </S.Links>
      </S.SoundCloudWrapper>
    </S.SoundCloudContainer>
  </S.Page>
))

SitePodcastPage.propTypes = {
  /** Short text about the page. */
  information: PropTypes.node.isRequired,
  /** List of paltforms that diffuse the podcast. */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      /** Logo of the company. Relative path to cloudinary. */
      logo: PropTypes.string.isRequired,
      /** Event triggered when link is pressed. */
      onClick: PropTypes.func.isRequired,
      /** This is the href where link is supposed to go. */
      path: PropTypes.string.isRequired,
      /** Defines the background of the link.  */
      theme: PropTypes.oneOf(['dark', 'light']),
    })
  ).isRequired,
  /** Properties of SoundCloud iframe. */
  soundcloud: PropTypes.shape({
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  /** Page title. */
  title: PropTypes.string.isRequired,
}

SitePodcastPage.displayName = 'SitePodcastPage'

export default memo(SitePodcastPage)
