export const SOUNDCLOUD = {
  src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/907843957%3Fsecret_token%3Ds-4v9bT&color=%2335ae77&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true',
  title: 'L’amour est dans le prêt',
}

export const LINKS = [
  {
    id: 'apple',
    logo: 'v1572438927/website/page/podcast/apple.png',
    path: 'https://podcasts.apple.com/fr/podcast/lamour-est-dans-le-pr%C3%AAt/id1486838314',
    theme: 'light',
  },
  {
    id: 'spotify',
    logo: 'v1572438927/website/page/podcast/spotify.png',
    path: 'https://open.spotify.com/show/3i8e0mgEfbzcSqlGcJeNHt',
    theme: 'dark',
  },
  // Put back on when path is ready
  // {
  //   id: 'google',
  //   logo: 'v1572438927/website/page/podcast/google.png',
  //   path: '#',
  //   theme: 'light',
  // },
  {
    id: 'deezer',
    logo: 'v1572438927/website/page/podcast/deezer.png',
    path: 'https://www.deezer.com/fr/show/655462',
    theme: 'light',
  },
]
